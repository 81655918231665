<template>
  <v-list-item>
    <v-list-item-content class="text-start">
      <v-list-item-title>
        {{ $t("order") }}: {{ order.serial }}
      </v-list-item-title>
      <v-list-item-subtitle
        v-text="
          formatDateAndTime({ date: order.created_at, timeZone: getTimeZone })
        "
      />
    </v-list-item-content>
    <v-list-item-content class="text-end">
      <v-list-item-title
        :class="`${getColorStatusOrder(order.status)}--text text-uppercase`"
      >
        {{ $t(order.status) }}
      </v-list-item-title>
      <v-list-item-subtitle
        v-text="
          `Total: ${order.request_price && formatPrice(getRequestPriceFinal)}`
        "
      />
    </v-list-item-content>
    <slot name="action" />
  </v-list-item>
</template>

<script>
import { formatPrice, formatDateAndTime, getColorStatusOrder } from "@/helpers";
import { orderTypeName } from "@/store/modules/orderType/constNames";

export default {
  name: "ItemOrder",
  props: {
    order: { type: Object, required: true },
  },
  methods: {
    formatDateAndTime,
    formatPrice,
    getColorStatusOrder,
  },
  computed: {
    getRequestPriceFinal() {
      const typeIsAtHome = this.order.type === orderTypeName.AT_HOME;
      return typeIsAtHome
        ? this.order.request_price + 1
        : this.order.request_price;
    },
    getTimeZone() {
      return this.$store.getters["user/getTimezone"];
    },
  },
};
</script>
